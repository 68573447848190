import React, {
  useState,
  useContext,
  useEffect,
  useMemo, 
  useRef
} from 'react'
import {
  getObjectStateChildStatistic,
} from '@/helpers/getTelementry'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import { ThemeContext } from 'styled-components'
import memoWithName from '@/hocs/memoWithName'
import { injectIntl } from 'react-intl'
import { treeHandlers } from 'react-hyper-tree'
import {
  clusterOptions,
} from '@/constants/maps'
import { circle } from "@turf/circle";
import zoneConfig from '@/data/mapTree/zoneConfig'
import { mapZoomBreakPoints } from '@/constants/maps'
import { getZoomByType } from '@/helpers/maps'

import { GEOZONE, OBJECT_ELEMENT } from '@/constants/objectTypes'
import renderJSXToString from '@/helpers/renderJSXToString'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'
import { getZoneStatistic } from '@/helpers/dataFormator'
import { StyledMap } from './styles'
import PinIcon from './components/PinIcon'
import PinIconHover from './components/PinIconHover'
import MeshWithStatsHover from './components/MeshWithStatsHover'
import ControllerIcon from './components/ControlerIcon'
import ControllerIconHover from './components/ControlerIconHover'
import ZoneIcon from './components/ZoneIcon'
import ZoneIconHover from './components/ZoneIconHover'
import ClusterIcon from './components/ClusterIcon'
import ClusterIconHover from './components/ClusterIconHover'
import {
  isController,
} from './utlis'
import { STATE_TO_STATUS } from '@/components/blocks/SidebarTree/components/TreeNode/component'
import CheckedIconPin from '@/components/blocks/YandexMap/components/CheckedIcon'
import CheckIconPinHover from '@/components/blocks/YandexMap/components/CheckIconHover'

import {
  YMap,
  YMapComponentsProvider,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapCustomClusterer,
  YMapMarker,
  YMapFeature,
  YMapListener,
} from "ymap3-components";

const YandexMap = (props) => {
  const {
    hoveredPinByID,
    handleOpenPassport,
    mash,
    globalZoneId,
    setSelectedZoneIdentifier,
    selectNodeByZone,
    zones,
    pinnedNode,
    pins,
  
    createPoint,
    pointsArray,
    createElementType,
    isDisplayElements,
    formRef,
    intl,
    yandexKey,
    isMonitoring,
    isPassportization,
    handleClickZone,
    objectStateFilters,
    connectedLinesCoordinates,
    isMashMode,
    isLinesMode,
    objectsGroups,
    groupCreateEditMode,
    isRussianBorders
  } = props
  const theme = useContext(ThemeContext)
  const [selectedZoneById, setSelectedZoneById] = useState(null)
  const rootZone = useMemo(() => zoneConfig[1] ,[])
  const [localMapZoom, setLocalMapZoom] = useState(4)
  const ymap3Ref = useRef();
  const [hoverMarkers, setHoverMarkers] = useState({});
  const [location, setLocation] = useState({ center: [86.628215, 63.362858], zoom: 4 })
  const updatePins = useMemo(() => {
    return pins.map((item) => {
      if (isController(item.type) && isDisplayElements) {
        return {
          ...item,
          statistic: getObjectStateChildStatistic(pins.filter((pin) => pin.parentId === item.id))
        }
      }
      return item
    })
  },
  [pins, isDisplayElements])
  useEffect(() => {
    if (pinnedNode.id) {
      setLocation({center: [pinnedNode.point.longitude, pinnedNode.point.latitude], zoom: getZoomByType(pinnedNode.type)})
      setLocalMapZoom(getZoomByType(pinnedNode.treeNodeType))
    }
    
  },[pinnedNode])

  const getBounds = (coordinates) => {
    let minLat = Infinity,
      minLng = Infinity;
    let maxLat = -Infinity,
      maxLng = -Infinity;
  
    for (const coords of coordinates) {
      const lat = coords[1];
      const lng = coords[0];
  
      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    }
  
    return [
      [minLng, minLat],
      [maxLng, maxLat]
    ];
  }

  const onClusterClick = (features) => {
      const bounds = getBounds(features.map((feature) => feature.geometry.coordinates));
      setLocation((prevLocation) => ({...prevLocation, bounds, ...{easing: 'ease-in-out', duration: 2000}}))
  }


  useEffect(() => {
    if (pinnedNode?.id && (!isMonitoring || !isPassportization)) {
      setHoverMarkers({[pinnedNode.id]: true})
    }
    return () => setHoverMarkers({})

  }, [pinnedNode, isMonitoring, isPassportization])

  const [activeMash, setActiveMash] = useState(0)

  useEffect(() => {
    if (mash.length > 0) {
      const interval = setInterval(() => {
        const mashIndex = activeMash < mash.length - 1
          ? (activeMash + 1)
          : 0
        setActiveMash(mashIndex)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [mash, activeMash, setActiveMash])

  const displayedZonesWithTelemetry = useMemo(() => {
    if (objectsGroups) {
      return []
    }
    const arrayOfDisableZonesIds = Object.keys(zones)
      .filter((elementKey) => elementKey !== 1)
    const disableZones = arrayOfDisableZonesIds.map((element) => zones[element])
    const disableZonesUpdate = disableZones.map((element) => getZoneStatistic(element, objectStateFilters, isMonitoring))
    return disableZonesUpdate.filter((zone) => zone.count !== 0 && zone.id !== globalZoneId)
  }, [globalZoneId, isMonitoring, zones, objectStateFilters, objectsGroups])

  const displayedPins = useMemo(() => {
    if (isMashMode) {
      return updatePins
    }
    if (objectsGroups) {
      return pins
    }
    if (!globalZoneId || !(zones[globalZoneId] || {}).id) {
      return []
    }
    const selector = (zones[globalZoneId] || {}).id || null

    const selectedPinsByZoneSelector = updatePins.filter((element) => element.geoZoneId === selector && element.treeNodeType === OBJECT_ELEMENT)
    return selectedPinsByZoneSelector.map((element) => ({
      ...element,
      location: element.location,
      name: element.name,
      status: isPassportization ? undefined : STATE_TO_STATUS[element.status]
    }))
  }, [globalZoneId, isPassportization, zones, isMashMode, updatePins, pins, objectsGroups])

  const zonesMaps = useMemo(()=>{
    if (objectsGroups) {
      return 
    }
      const zonesID = Object.keys(zones)
      return zonesID.map((id) => zones[id])
  },[zones, objectsGroups])

  const selectZoneHandler = (zone) => {
    const updateZone ={
      ...zone,
      location: zone.location
    }
    setSelectedZoneIdentifier(updateZone.id)
    selectNodeByZone(updateZone)
    setSelectedZoneById(null)
    handleClickZone(updateZone)
  }


  const hoverSelectedPins = (element, noHover = false) => {
    const activeElementId = hoveredPinByID || selectedZoneById || pinnedNode.id
    if (element.mash && element.id === hoveredPinByID) {
      return MeshWithStatsHover
    }
    if (element.mash) {
      return PinIcon
    }

    if (objectsGroups) {
      return element.checked ? CheckedIconPin : PinIcon
    }

    if (
      isController(element.installationType || element.type )
      && element.id === activeElementId
      && !createPoint
      && !isPassportization
      && element.count
    ) {
      return ControllerIconHover
    }

    if (isController(element.installationType || element.type) && !isPassportization && element.count) {
      return ControllerIcon
    }

    return PinIcon
  }
  const createUpdateElement = (element) => {
    if (objectsGroups) {
      return element
    }
    const count = Object.keys(element?.statistic).reduce((accumulator, type) => {
      const typeCount = element?.statistic[type] || 0
        return accumulator + typeCount
      }, 0)
    return {
      ...element,
      count,
    }
  }

  const createIconPin = (icon, element, pinColor) => {
    return renderJSXToString(
      icon,
      element,
      theme,
      intl,
      isMonitoring,
      isPassportization,
      pinColor
    )
  }

  const pinOnClickHandler = (object) => {
    if (objectsGroups && groupCreateEditMode) {
        const tree = treeHandlers.trees['objects-groups-form-tree']
        const path = object.objectData.original.options.path
        tree.handlers.setSelectedByPath(path.slice(1))
        object.objectData.checked = !object.objectData.checked
        // objects.setObjectProperties(id, createIconPin(CheckedIconPin, element, color))
        // handleCheckNode([element], false)
      return
    }
    handleOpenPassport(object.objectData)
  }

  const onCreatePointHandler = (layer, event, object) => {
    if (createPoint) {
      const setFieldValue = get(formRef.current, 'setFieldValue', noop)
      if (createElementType === 'city'){
        setFieldValue(CREATE_REGION_NAMES.LATITUDE, event.coordinates[1].toFixed(6))
        setFieldValue(CREATE_REGION_NAMES.LONGITUDE, event.coordinates[0].toFixed(6))
      } else {

      setFieldValue('LOCATION.LATITUDE', event.coordinates[1].toFixed(6))
      setFieldValue('LOCATION.LONGITUDE', event.coordinates[0].toFixed(6))
      }


    }
  }

  

  const featureCollectionPins = useMemo(() => {
    const features = []
    displayedPins.forEach((pin) => {
      if (isController(pin.installationType)) {
        pin = createUpdateElement(pin)
      }
      const properties = {
        iconContent: renderJSXToString(
          hoverSelectedPins(pin),
          pin,
          theme,
          intl,
          isMonitoring,
          isPassportization,
          pin.color
        ),
      }
        

      if (pin.parentTreeId && pin.elementType !== GEOZONE && pin.type !== 'UNKNOWN') {
        features.push(
          {
            "type": "Feature",
            "id": pin.id,
            "geometry": {
              "type": "Point",
              "coordinates": pin.location.reverse()
            },
            // 'options': options,
            "properties": properties,
            'objectData' : pin
          })

      }
    })

    return features

  },[displayedPins, intl, theme])

  const onBoundsChange = (event) => {
    const bounds = event.location.bounds
    const visibleFeatures = featureCollectionPins.filter(feature => {
      const [lng, lat] = feature.geometry.coordinates
      return lng >= bounds[0][0] && 
             lng <= bounds[1][0] && 
             lat >= bounds[1][1] && 
             lat <= bounds[0][1]
    })
    console.log(`Объектов в видимой области: ${visibleFeatures.length}`)
  }

const cluster = (coordinates, features) => {
  const clusterId = `${features[0].id}-${features.length}`
    return (
    <YMapMarker key={`${features[0].id}-${features.length}`} coordinates={coordinates} zIndex={hoverMarkers[clusterId]? 99999 : 0}>
      <div
      onMouseEnter={() => setHoverMarkers({[clusterId]: true})}
      onMouseLeave={() => setHoverMarkers({})}
      onClick={()=> onClusterClick(features)}
      >
      {renderJSXToString(hoverMarkers[clusterId] && !isPassportization? ClusterIconHover : ClusterIcon, features, theme, intl, isMonitoring, isPassportization)}
      </div>

    </YMapMarker>
  )}

const marker = (feature) => {
    return (
      <YMapMarker coordinates={feature.geometry.coordinates} zIndex={hoverMarkers[feature.id]? 9999 : 0} >
        <div 
          onMouseEnter={() => setHoverMarkers({[feature.id]: true})}
          onMouseLeave={() => setHoverMarkers({})}
          onClick={()=> pinOnClickHandler(feature)}
        >
        {hoverMarkers[feature.id]? createIconPin(objectsGroups ? CheckIconPinHover : PinIconHover, feature.objectData) : feature.properties.iconContent}
        </div>
      </YMapMarker>
  )}

  const renderLines = useMemo(() => {
    if (!connectedLinesCoordinates) {
      return null
    }
    return (
      connectedLinesCoordinates.map((lines) => (
        <YMapFeature
          geometry={{
            type: 'LineString',
            coordinates: lines.map((line) => line.reverse()),
          }}
          style={{
            stroke: [{width: 3, color: theme.colors.colors.title}]
          }}
        />
      ))
    )
  }, [connectedLinesCoordinates, theme])

  const renderMashLines = useMemo(() => {
    return (
      mash[activeMash]?.map((lines) => (
        <YMapFeature
          geometry={{
            type: 'LineString',
            coordinates: lines.map((line) => line.reverse()),
          }}
          style={{
            stroke: [{width: 3, color: '#06AAF2'}]
          }}
        />
      ))
    )

  }, [mash, activeMash])

  return (
      <YMapComponentsProvider apiKey={yandexKey} >
        <StyledMap >
        <YMap 
          location={location}
          zoomRange={{min: 3, max: 19}}
          ref={ymap3Ref}
          >
          <YMapDefaultSchemeLayer/>
          <YMapDefaultFeaturesLayer />
          <YMapListener layer='any' onActionEnd={(event) => {
            setLocalMapZoom(Math.floor(event.location.zoom))
            onBoundsChange(event)
            }} 
            onClick={onCreatePointHandler}
          />
           {!createPoint && 
              <YMapCustomClusterer
                cluster={cluster}
                marker={marker}
                gridSize={128}
                features={featureCollectionPins}
                maxZoom={17}
              />
            }
            {!createPoint && !isMashMode && !objectsGroups && displayedZonesWithTelemetry.map((zone) => 
              <YMapMarker coordinates={[zone.point.longitude, zone.point.latitude]} zIndex={selectedZoneById === zone.id ? 9999 : 0}>
                <div 
                  onClick={() => selectZoneHandler(zone) }
                  onMouseEnter={() => setSelectedZoneById(zone.id)}
                  onMouseLeave={() => setSelectedZoneById(null)}
                  >
                  {renderJSXToString(
                    selectedZoneById === zone.id ? 
                      ZoneIconHover 
                    : 
                      ZoneIcon,
                    zone,
                    theme,
                    intl,
                    isMonitoring,
                    isPassportization,
                  )}
                </div>
              </YMapMarker>
            )}
            {!createPoint && !isMashMode && !objectsGroups && zonesMaps.map((zone) => 
              <YMapFeature 
              geometry={circle(zone.location, zone.radius * 1000, {units: 'meters'}).geometry} 
              style={{
                simplificationRate: 0,
                stroke: [
                {
                  color: 'red',
                  width: 2,
                }
              ],
              fill: '#00000000'}} 
              />
            )}
            {isLinesMode && !createPoint && localMapZoom >= mapZoomBreakPoints.max - 2 && connectedLinesCoordinates &&
              renderLines 
            }
            {isMashMode && !createPoint && localMapZoom >= mapZoomBreakPoints.max - 2 && 
              renderMashLines
            }
            {isDisplayElements && !!pointsArray.length && pointsArray.map((point) => {
              if (point.type === 'city'){
                return (
                  <YMapFeature
                    geometry={circle([point.location[1], point.location[0]], point.radius * 1000, {units: 'meters'}).geometry}
                    style={{
                      simplificationRate: 0,
                      stroke: [
                        {
                          color: 'red',
                          width: 2,
                        }
                      ],
                      fill: '#00000000'
                    }}
                  />
                )
              } else {
                return (
                  <YMapMarker coordinates={[point.location[1], point.location[0]]} zIndex={0}>
                    <div                >
                      {renderJSXToString(PinIcon, point, theme, intl)}
                    </div>
                  </YMapMarker>
                )
              }
            })}

            {isRussianBorders && !createPoint && 
                <YMapFeature
                geometry={{
                  type: 'Polygon',
                  coordinates: [rootZone.border],
                }}
                style={{
                  stroke: [
                    {
                      color: 'red',
                      width: 2,
                    }
                  ],
                  fill: '#00000000'
                }}
              />
            }
        </YMap>
        </StyledMap>
     </YMapComponentsProvider>
    );
}

YandexMap.defaultProps = {
  pinnedNode: {},
  defaultState: { center: [55.75, 37.57], zoom: 9, controls: [] },
  clustersOptions: clusterOptions,
  zones: pt.objectOf(pt.object),
  regionsWithPins: false,
  hoveredZoneByID: null,
  hoveredPinByID: null,
  createPoint: false,
  isDisplayElements: false,
  pointsArray: [],
  displayElements: [],
  updateMapZoom: noop,
  updateMapCenter: noop,
  handleOpenPassport: noop,
  setSelectedZoneIdentifier: noop,
  onClick: noop,
  pinOnEnterHandler: noop,
  pinOnLeaveHandler: noop,
  selectNodeByZone: noop,
  handleClickZone: noop,
  yandexKey: '',
  isMonitoring: false,
}

YandexMap.propTypes = {
  pinnedNode: pt.objectOf(pt.shape({
    treeElementId: pt.oneOfType([pt.string, pt.number]),
  })),
  defaultState: pt.shape({
    zoom: pt.number,
    center: pt.arrayOf(pt.number),
  }),
  clustersOptions: pt.shape({
    groupByCoordinates: pt.bool,
    clusterDisableClickZoom: pt.bool,
    clusterHideIconOnBalloonOpen: pt.bool,
    geoObjectHideIconOnBalloonOpen: pt.bool,
    clusterNumbers: pt.arrayOf(pt.number),
  }),
  zones: {},
  mapCenter: pt.arrayOf(pt.number).isRequired,
  hoveredPinByID: pt.number,
  hoveredZoneByID: pt.number,
  mapZoom: pt.number.isRequired,
  regionsWithPins: pt.bool,
  isDisplayElements: pt.bool,
  updateMapZoom: pt.func,
  updateMapCenter: pt.func,
  handleOpenPassport: pt.func,
  onClick: pt.func,
  pinOnEnterHandler: pt.func,
  pinOnLeaveHandler: pt.func,
  handleClickZone: pt.func,
  setSelectedZoneIdentifier: pt.func,
  createPoint: pt.bool,
  pointsArray: pt.arrayOf(pt.object),
  displayElements: pt.arrayOf(pt.object),
  yandexKey: pt.string,
  isMonitoring: pt.bool,
}

export default injectIntl(memoWithName(YandexMap))
