import React, {
  useCallback,
  useMemo,
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
// import DefaultImage from '@/components/blocks/DefaultImage'
import PassportForm from '@/forms/PassportizationForm'
import CkeckBox from '@/components/controls/CheckBox'
import TabsController from '@/components/blocks/TabsController'
import {defaultImage, lightFixtureImage, prodDefaultImage, productionOnlyImage} from '@/assets/images/objectElement'
import defaultPassport from '@/assets/passports'

import {
  CARD,
  PASSPORT,
} from '@/constants/passportization'

import {
  ContentWrapper,
  TabContainer,
  PassportImageContainer,
  PassportImage,
  TitleContainer,
  CkeckBoxContainer,
  OptionTitle,
  CkeckBoxsContainer,
} from './styles'
import { formatDataForObject } from '@/containers/pages/Installation/utils'
import {
  LIGHT_FIXTURE,
  LIGHT_FIXTURE_CONSOLE_LED,
  LIGHT_FIXTURE_CONSOLE_PARK_DNAT,
  LIGHT_FIXTURE_CONSOLE_ZKU, LIGHT_FIXTURE_FLOOR_LED, LIGHT_FIXTURE_FLOOR_ZKU,
  LIGHT_FIXTURE_GALEON_L,
  LIGHT_FIXTURE_GALAD,
  LIGHT_FIXTURE_GALEON_S, LIGHT_FIXTURE_SPOTLIGHT_LED,
  LIGHT_FIXTURE_SUSPENDED_LED,
  LIGHT_FIXTURE_SUSPENDED_ZKU,
  LIGHT_FIXTURE_WALL_LED,
  LIGHT_FIXTURE_WALL_ZKU,
  LIGHT_FIXTURE_GALEON_S_LED,
  UTILITY_POLE,
  UTILITY_POLE_CONCRETE,
  UTILITY_POLE_METAL,
  UTILITY_POLE_WOOD,
  LAMP_MODULE,
  CONTROL_CUPBOARD,
  UNILIGHT,
  WITHOUT_ASU,
} from '@/constants/instalationPassport/types'

const TABS = [
  {
    title: <Lang id="passportization.passport" />,
    value: PASSPORT
  },
  {
    title: <Lang id="passportization.card" />,
    value: CARD
  },
]

const PassportAndCard = ({
  operatedElement,
  cardData,
  cardScheme,
  passportData,
  passportScheme,
  selectedFields,
  setDirty,
  passportRef,
  setUploadedImage,
  uploadedImage,
  handleExportFile,
  handleDownloadFile,
  block,
  tabType,
  setTabType,
  intl,
  isExportButtonVisible,
  objectConnectedIdentifiers,
  isMobile,
  collumnId,
}) => {
  const setTabTypeHandler = (type) => () => { setTabType(type) }
  const setTabTypeHandlerMobile = (type) => { setTabType(type) }

  const data = useMemo(() => {
    return formatDataForObject({
      ...cardData,
      ...passportData,
    }, intl)
  }, [cardData, passportData, intl])

  const getDefaultPassport = () => {
    if (data.objectType === CONTROL_CUPBOARD) {
      return {
        name: 'Паспорт ШУНО Unilight',
        file: defaultPassport[CONTROL_CUPBOARD]
      }
    }

    if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Галеон S LED')) {
      return {
        name: 'Паспорт на Галеон серии S LED',
        file: defaultPassport[LIGHT_FIXTURE_GALEON_S_LED]
      }    
    }
    
    if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Галеон S')) {
      return {
        name: 'Паспорт на Галеон серии S',
        file: defaultPassport[LIGHT_FIXTURE_GALEON_S]
      }    
    }

    if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Галеон L')) {
      return {
        name: 'Паспорт на Галеон серии L',
        file: defaultPassport[LIGHT_FIXTURE_GALEON_L]
      }    
    }

    if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('GALAD')) {
      return {
        name: 'Паспорт на GALAD Волна',
        file: defaultPassport[LIGHT_FIXTURE_GALAD]
      }    
    }
    
    if (data.objectType === LAMP_MODULE) {
      return {
        name: 'Паспорт Модуль Unilight NEMA',
        file: defaultPassport[LAMP_MODULE]
      }    
    }
    return ''
  }

  const formScheme = useMemo(() => (
    tabType === CARD ? cardScheme : passportScheme
  ), [passportScheme, cardScheme, tabType])

  const photoUrl = get(data, 'inner.photo.value', '')

  const getImageUrlProductionOnly = useCallback(() => { // SLS-1911
    if (data.objectType === UTILITY_POLE) {
      if (!!data.TECHNICAL_CONSTRAINTS?.UTILITY_POLE_TYPE?.includes('бетонная')) {
        return productionOnlyImage[UTILITY_POLE_CONCRETE]
      }

      if (!!data.TECHNICAL_CONSTRAINTS?.UTILITY_POLE_TYPE?.includes('металлическая')) {
        return productionOnlyImage[UTILITY_POLE_METAL]
      }

      return productionOnlyImage[UTILITY_POLE_WOOD]
    }

    if (data.objectType === LIGHT_FIXTURE) {
      switch (data.TECHNICAL_CONSTRAINTS?.LAMP_TYPE) {
        case 'Консольный парковый ДНаТ': return productionOnlyImage[LIGHT_FIXTURE_CONSOLE_PARK_DNAT]
        case 'Консольный парковый светодиодный':
        case 'Консольный светодиодный': return productionOnlyImage[LIGHT_FIXTURE_CONSOLE_LED]
        case 'Настенный / потолочный ЖКУ': return productionOnlyImage[LIGHT_FIXTURE_WALL_ZKU]
        case 'Настенный / потолочный светодиодный': return productionOnlyImage[LIGHT_FIXTURE_WALL_LED]
        case 'Подвесной деко ЖКУ': return productionOnlyImage[LIGHT_FIXTURE_SUSPENDED_ZKU]
        case 'Подвесной деко светодиодный': return productionOnlyImage[LIGHT_FIXTURE_SUSPENDED_LED]
        case 'Прожектор светодиодный': return productionOnlyImage[LIGHT_FIXTURE_SPOTLIGHT_LED]
        case 'Торшерный деко ЖКУ':
        case 'Торшерный ЖКУ': return productionOnlyImage[LIGHT_FIXTURE_FLOOR_ZKU]
        case 'Торшерный деко светодиодный': return productionOnlyImage[LIGHT_FIXTURE_FLOOR_LED]

        default: return productionOnlyImage[LIGHT_FIXTURE_CONSOLE_ZKU]
      }
    }

    return prodDefaultImage[data.objectType]
  }, [data])

  const getImageUrl = useCallback(() => {
    if (data.path[3] === 50061 || data.path[3] === 4459) {      // change integration id before merge in prod SLS-1911
      return getImageUrlProductionOnly()
    }

    if (!!operatedElement.url) {
      return operatedElement.url
    }

    if (!!photoUrl) {
      return photoUrl
    }
    // temporary added, remove after Demo SLS-1794
    if (data.objectType === LIGHT_FIXTURE) {
      if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Галеон S')) {
        return lightFixtureImage[LIGHT_FIXTURE_GALEON_S]
      }

      if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Галеон L')) {
        return lightFixtureImage[LIGHT_FIXTURE_GALEON_L]
      }

      if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('GALAD')) {
        return lightFixtureImage[LIGHT_FIXTURE_GALAD]
      }

      return lightFixtureImage[LIGHT_FIXTURE]
    }

    return defaultImage[data.objectType]
  }, [operatedElement, data, photoUrl, getImageUrlProductionOnly])

  const getInitialValues = (values) => {
    if (!passportData.fileId) {
      if (((passportData.integrationType === WITHOUT_ASU && !passportData.installationType.includes('ART'))&& (data.path[3] !== 50061 || data.path[3] !== 4459)) || passportData.integrationType === UNILIGHT ) // change integration id before merge in prod SLS-1794
      return {
        ...values,
        'ATTACHMENT': {
          ...values['ATTACHMENT'],
          'DOCUMENT_TITLE' : getDefaultPassport(),
        }
      }
    }
    return values
  }
  return (
    <ContentWrapper>
      {isMobile 
        ?
          <TabsController
            nameStyles="fullWidth"
            activeTab={tabType}
            tabs={TABS}
            onTabChange={setTabTypeHandlerMobile}
            withUrlChange={false}
          /> 
        :
        <TabContainer>
          <TitleContainer>
            <Lang id="passportization.passportSidebar.view" />
          </TitleContainer>
          <CkeckBoxsContainer>
            <CkeckBoxContainer onClick={setTabTypeHandler(PASSPORT)}>
              <CkeckBox value={tabType === PASSPORT} />
              <OptionTitle>
                <Lang id="passportization.passportSidebar.passport" />
              </OptionTitle>
            </CkeckBoxContainer>
            <CkeckBoxContainer onClick={setTabTypeHandler(CARD)}>
              <CkeckBox value={tabType === CARD} />
              <OptionTitle>
                <Lang id="passportization.passportSidebar.card" />
              </OptionTitle>
            </CkeckBoxContainer>
          </CkeckBoxsContainer>
        </TabContainer>
      }
      <PassportImageContainer>
      <PassportImage url={getImageUrl()} base64Url={uploadedImage} />
        {/* {operatedElement.url || uploadedImage || photoUrl
          ? (<PassportImage url={operatedElement.url || photoUrl} base64Url={uploadedImage} />)
          : (<DefaultImage title={(<Lang id="passportization.passportSidebar.noImage" />)} />)} */}
      </PassportImageContainer>
      <PassportForm
        initialValues={getInitialValues(data)}
        options={formScheme}
        disabledFields={[]}
        setSelectedPassportFields={noop}
        setImage={setUploadedImage}
        selectedFields={selectedFields}
        setDirty={setDirty}
        ref={passportRef}
        handleExportFile={handleExportFile}
        handleDownloadFile={handleDownloadFile}
        block={block}
        isExportButtonVisible={isExportButtonVisible}
        objectConnectedIdentifiers={objectConnectedIdentifiers}
        collumnId={collumnId}
      />
    </ContentWrapper>
  )
}

PassportAndCard.propTypes = {
  operatedElement: pt.objectOf(pt.object),
  cardData: pt.objectOf(pt.object),
  cardScheme: pt.arrayOf(pt.object),
  passportData: pt.objectOf(pt.object),
  passportScheme: pt.arrayOf(pt.object),
  selectedFields: pt.arrayOf(pt.string),
  exportPassport: pt.func,
  exportCart: pt.func,
  block: pt.bool,
}

PassportAndCard.defaultProps = {
  operatedElement: {},
  cardData: {},
  cardScheme: [],
  passportData: {},
  passportScheme: [],
  selectedFields: [],
  exportPassport: noop,
  exportCart: noop,
  block: false,
}
export default PassportAndCard
